import React, { Component } from 'react';

import Modal from 'react-bootstrap/Modal';

import RealNavbar from '../partials/RealNavbar';
import RealFooter from '../partials/RealFooter';

import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';

const actionCodeSettings = {
  url: (window.location.hostname === 'realdatingapp.com') ?
    `https://realdatingapp.com/join?r=${window.location.pathname}` : `http://localhost:3000/join?r=${window.location.pathname}`,
  handleCodeInApp: true,
};

class RealHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      emailValue: '',
      isInvalidEmail: true,
      isLoadingHidden: true,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModalClosure = this.handleModalClosure.bind(this);
    this.conversionTrackers = this.conversionTrackers.bind(this);
    this.handleAppStoreClick = this.handleAppStoreClick.bind(this);
    this.handlePlayStoreClick = this.handlePlayStoreClick.bind(this);
  }

  handleEmailChange(e) {
    // eslint-disable-next-line no-control-regex
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    this.setState({ emailValue: e.target.value, isInvalidEmail: !emailRegex.test(e.target.value) });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.isInvalidEmail) {
      console.log('An email was submitted: ' + this.state.emailValue);
      const email = this.state.emailValue;

      // Switch to loading state
      this.setState({
        emailValue: '',
        isInvalidEmail: true,
        isLoadingHidden: false
      });

      // Send Firebase Email Link
      const auth = getAuth();
      sendSignInLinkToEmail(auth, email, actionCodeSettings).then(() => {
        window.localStorage.setItem('emailForSignIn', email);
        this.setState({ showModal: true });
      }).catch((err) => {
        // TODO! show error modal
        console.log(err);
        this.setState({ showModal: true });
      });

    }
  }

  handleModalClosure() {
    this.setState({ isLoadingHidden: true, showModal: false });
  }

  conversionTrackers() {
    return new Promise((resolve, reject) => {
      // TODO implement affiliate tracking
      resolve(false);
    });
  }

  handleAppStoreClick(e) {
    e.preventDefault();
    this.conversionTrackers().then((ok) => {
      if (ok) {
        console.log('Conversion tracked OK.');
      }
    }).catch(err => console.error).finally(() => {
      window.location.assign("#");
    });
  }

  handlePlayStoreClick(e) {
    e.preventDefault();
    this.conversionTrackers().then((ok) => {
      if (ok) {
        console.log('Conversion tracked OK.');
      }
    }).catch(err => console.error).finally(() => {
      window.location.assign("#");
    });
  }

  render() {
    return (
      <>
        <div className="home">

          <RealNavbar />

          <section className="pt-4">
            <div className="container">
              <div className="row align-items-center justify-content-center justify-content-md-between">

                <div className="col-12 col-md-6" data-aos="fade-right">

                  <h1 className="fw-bold">
                    Hello! <span className="text-primary">Toku</span>. <br />
                    International <span className="text-primary">calls</span>.
                  </h1>

                  <h4 className="fw-bold text-muted">Stay Close, No Matter the Distance</h4>

                  <p className="fs-lg text-muted">
                    Make affordable direct calls to bring you closer to the people you love.
                  </p>

                  <div className="fw-bold text-muted">
                    <a href="#" target="_blank" rel="noreferrer" onClick={this.handleAppStoreClick}>
                      <img src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" alt="Download on the App Store" style={{ height: 50 }} />
                    </a>
                    <a href="#" target="_blank" rel="noreferrer" onClick={this.handlePlayStoreClick}>
                      <img src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" alt="Get it on Google Play" style={{ height: 70 }} />
                    </a>
                  </div>
                </div>

                <div className="col-10 col-sm-8 col-md-6 order-md-2 d-none d-md-block">

                  <div className="device-combo device-combo-iphonex-iphonex mb-6 mb-md-0">

                    <div className="device device-iphonex">
                      {/* <img src="/static/img/screenshots/mobile/real-john.jpg" className="device-screen" alt="..." /> */}
                      <span className="device-screen text-center" style={{ padding: "2rem" }}>Coming soon</span>
                      <img src="/static/img/devices/iphonex.svg" className="img-fluid" alt="..." />
                    </div>

                    <div className="device device-iphonex">
                      {/* <img src="/static/img/screenshots/mobile/real-jane.jpg" className="device-screen" alt="..." /> */}
                      <img src="/static/img/devices/iphonex.svg" className="img-fluid" alt="..." />
                    </div>

                  </div>

                </div>

              </div>
            </div>
          </section>

          <section className="pt-6">
            <div className="container">
              <div className="row align-items-center justify-content-between">

                <div className="col-12 col-md-6 mb-5 mb-md-0">
                  <div className="row">
                    <div className="col-6 me-n5">

                      <img src="/static/img/unsplash/photo-1615890643735-6cd26210d646.avif" alt="..." className="img-fluid mb-4 rounded" data-aos="fade-right" data-aos-delay="100" />

                      <img src="/static/img/unsplash/photo-1676119451563-0c4a1a37e019.avif" alt="..." className="img-fluid rounded" data-aos="fade-right" data-aos-delay="150" />

                    </div>
                    <div className="col-6 mt-8">

                      <img src="/static/img/unsplash/photo-1620038352413-03b1a1a6ca59.avif" alt="..." className="img-fluid mb-4 rounded" data-aos="fade-right" />

                      <img src="/static/img/unsplash/photo-1694119243549-35ab499dee3d.avif" alt="..." className="img-fluid rounded" data-aos="fade-right" data-aos-delay="50" />

                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6" data-aos="fade-left">
                  <h2 className="fw-bold">
                    Toku <span className="text-primary">calls</span>.
                  </h2>
                  <p className="fs-lg text-muted mb-4">
                    Keep in touch with friends and family, no matter where you are.

                    Crystal-clear audio quality for conversations that feel as close as a hug.

                    Easy to use, even for those who aren't tech-savvy.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="py-8 py-md-11">
            <div className="container">
              <div className="row align-items-center justify-content-between">

                <div className="col-12 col-md-6 col-lg-7 order-md-2">
                  <img src="/static/img/undraw/undraw_real_time_collaboration_c62i.svg" alt="..." className="img-fluid mb-8 mb-md-8 mb-md-0" data-aos="fade-left" />
                </div>

                <div className="col-12 col-md-6 col-lg-5 order-md-1" data-aos="fade-right">
                  <h2 className="fw-bold">
                    How to use <span className="text-primary">Toku</span>
                  </h2>
                  <p className="fs-lg text-muted mb-0">
                    <ol>
                      <li>Download the Toku app - Available on iOS and Android.</li>
                      <li>Add Credit - Top up easily for the best rates.</li>
                      <li>Call Your Loved Ones - Enjoy uninterrupted, crystal-clear calls at affordable prices.</li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="py-8 py-md-11">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-12 col-md-6 col-lg-7 order-xs-2 order-md-1">
                  <img src="/static/img/undraw/undraw_night_calls_-5-jh7.svg" alt="..." className="img-fluid mb-8 mb-md-8 mb-md-0" data-aos="fade-left" />
                </div>

                <div className="col-12 col-md-6 col-lg-5 order-md-2" data-aos="fade-right">
                  <h2 className="fw-bold">
                    What Our <span className="text-primary">Users </span> Are Saying
                  </h2>
                  <p className="fs-lg text-muted mb-0">
                    <span className="text-italic">
                      "Toku made it so easy for me to call my aunt back home. The rates are great, and I never have to worry about connection issues."
                    </span> - Nyachama
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-6 pt-md-8 d-none">
            <div className="container pb-6 pb-md-8 border-bottom">
              <div className="row align-items-center">
                <div className="col-12 col-md">

                  <h3 className="fw-bold mb-4">
                    It's time to get <span className="text-primary">Real </span> now.
                  </h3>

                </div>

                <div className="col-auto">

                  <a href="#!" className="mb-4 mb-md-0 text-reset d-inline-block me-1">
                    <img src="assets/img/buttons/button-app.png" className="img-fluid" alt="..." style={{ maxWidth: '155px' }} />
                  </a>

                  <a href="#!" className="text-reset d-inline-block">
                    <img src="assets/img/buttons/button-play.png" className="img-fluid" alt="..." style={{ maxWidth: '155px' }} />
                  </a>

                </div>
              </div>
            </div>
          </section>

          <RealFooter />
        </div>
      </>
    )
  }
}

export default RealHome;
