import React, { Component } from 'react';

import Spinner from 'react-bootstrap/Spinner';

import RealNavbar from '../partials/RealNavbar';
import RealFooter from '../partials/RealFooter';

import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';

import { db } from '../index';

var isProcessingJoin = false;

const updateUser = async (user, email, referredBy) => {
  try {
    // fetch country code in production
    var country = 'n/a';
    if (window.location.hostname === 'realdatingapp.com') {
      let res = await fetch('/workers/country');
      let data = await res.json();
      country = data.country;
    }
    // insert user doc
    await setDoc(doc(db, 'users', user.uid), {
      createdAt: serverTimestamp(),
      referredBy, country,
    });
  } catch (err) {
    console.log(err);
  } finally {
    return true;
  }
}

const handleJoin = (self) => {
  if (isProcessingJoin === false) {
    isProcessingJoin = true;

    // Confirm the link is a sign-in with email link.
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Get metadata
      let email = window.localStorage.getItem('emailForSignIn');
      let search = new URLSearchParams(window.location.search);
      let pathname = search.get('r');

      // Confirm the email if we don't have one
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }

      // Sign in
      signInWithEmailLink(auth, email, window.location.href).then(async (result) => {
        await updateUser(result.user, email, pathname);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        self.setState({ isLoadingHidden: true, isProcessing: false });
      });
    }
  }
}

class RealJoin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoadingHidden: false,
      isProcessing: false,
    }
    this.handleBack = this.handleBack.bind(this);
  }

  handleBack(e) {
    e.preventDefault();
    window.location.pathname = '/';
  }

  componentDidMount() {
    handleJoin(this);
  }

  render() {
    return (
      <>
        <div className="waitlist">
          <RealNavbar />

          <section className="section-border border-primary">
            <div className="container d-flex flex-column">
              <div className="row align-items-center justify-content-center my-6 my-md-12">
                <div className="col-12 col-md-8">

                  <div className="card">
                    <div className="card-header pt-0 pb-3">
                      <h2 className="fw-bold">
                        Thanks for joining!
                      </h2>
                    </div>
                    <div className="card-body">
                      <p className="mb-5 text-gray-700">
                        We are still working on the app but as soon as it's ready we will send you the link to get the app.
                        <br></br>
                        <br></br>
                        Our estimated launch date is:
                        <strong> June 30, 2024.</strong>
                        <br></br>
                        <br></br>
                        We might invite you earlier by email for testing and feedback.
                        <br></br>
                        <br></br>
                        Many thanks,
                        <br></br>
                        The Real Team
                      </p>
                      <div className="text-center pt-3">
                        <button type="button" className="btn btn-primary" href="/" disabled={!this.state.isLoadingHidden} onClick={this.handleBack}>
                          <Spinner
                            hidden={this.state.isLoadingHidden}
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="visually-hidden">Loading...</span>
                          <span hidden={!this.state.isLoadingHidden}>Back to home</span>

                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <hr className="hr-sm my-6 my-md-8 mx-auto bg-gray-300" />

          <RealFooter />
        </div>
      </>
    )
  }
}

export default RealJoin;
