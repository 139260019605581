import './styles/App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import RealHome from './routes/RealHome';
import RealJoin from './routes/RealJoin';
import RealStats from './routes/RealStats';
import RealTerms from './routes/RealTerms';
import RealPrivacy from './routes/RealPrivacy';
import RealRedirect from './routes/RealRedirect';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<RealHome/>}/>
        <Route exact path="/join" element={<RealJoin/>}/>
        <Route exact path="/stats/*" element={<RealStats/>}/>
        <Route exact path="/terms" element={<RealTerms/>}/>
        <Route exact path="/privacy" element={<RealPrivacy/>}/>
        <Route exact path="/redirect/*" element={<RealRedirect/>}/>
        <Route path="*" element={<RealHome/>}/>
      </Routes>
    </Router>
  );
}

export default App;
