import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import RealNavbar from '../partials/RealNavbar';
import RealFooter from '../partials/RealFooter';

import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../index';

import moment from 'moment';

var isProcessingQuery = false;

const handleQuery = async (self) => {
  if (isProcessingQuery === false) {
    isProcessingQuery = true;
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('referredBy', '==', `/${self.state.username}`), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size > 0) {
        // Show table
        self.setState({ isLoadingHidden: true });
        // Results
        var days = {};
        // Iterate and append data to state
        for await (const doc of querySnapshot.docs) {
          let data = doc.data();
          var day = moment(data.createdAt.toDate()).format('DD-MM-YYYY');
          if (days[day]) {
            days[day]++;
          } else {
            days[day] = 1;
          }
        }
        // Save state as array
        var stats = [];
        Object.keys(days).forEach((value, index) => {
          stats.push({ day: value, value: days[value] });
        })
        return self.updateStats(stats);
      }
    } catch (err) {
      console.log(err);
    }
  }
}

class RealStats extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: window.location.pathname.split('/')[2],
      isLoadingHidden: false,
      stats: [],
    }
    this.updateStats = this.updateStats.bind(this);
  }

  updateStats(stats) {
    this.setState({ stats });
    // console.log(stats);
    //this.forceUpdate();
  }

  componentDidMount() {
    if (!this.state.username) {
      window.location.pathname = '/';
    } else {
      return handleQuery(this);
    }
  }

  render() {
    return (
      <>
        <div className="stats">
          <RealNavbar />

          <nav className="bg-gray-200">
            <div className="container">
              <div className="row">
                <div className="col-12">

                  <ol className="breadcrumb breadcrumb-scroll">
                    <li className="breadcrumb-item">
                      <a href={this.state.username} className="text-gray-700">
                        Statistics for
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {this.state.username}
                    </li>
                  </ol>

                </div>
              </div>
            </div>
          </nav>

          <section className="pt-6 pt-md-8 bg-light">
            <div className="container">

              <div className="row">
                <div className="col-12">

                  <div className="row mb-6 mb-md-8">
                    <div className="col-auto">

                      <div className="icon-circle bg-primary text-white">
                        <i hidden={!this.state.isLoadingHidden} className="fe fe-users"></i>
                        <Spinner hidden={this.state.isLoadingHidden} animation="grow" />
                      </div>

                    </div>
                    <div className="col ms-n4">

                      <h2 className="fw-bold mb-0">
                        Referral Program
                      </h2>

                      <p className="fs-lg text-gray-700 mb-0">
                        Thank you <span className="text-primary">{this.state.username}</span> for helping us promote Real!
                      </p>

                    </div>
                  </div>
                </div>
              </div>

              <hr className="hr-sm my-6 my-md-8 mx-auto bg-gray-300" />

              <p className="text-gray-700 text-center mx-6" hidden={this.state.isLoadingHidden}>
                The number of users you referred will appear here
              </p>

              <div className="row" hidden={!this.state.isLoadingHidden}>
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">#</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.stats.map((stat, i) => {
                                return (
                                  <tr key={i}>
                                    <th scope="row">{stat.day}</th>
                                    <td>{stat.value}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="hr-sm my-8 my-md-10 mx-auto bg-white" />

            </div>
          </section>

          <RealFooter />
        </div>
      </>
    )
  }
}

export default RealStats;
