import React, { Component } from 'react';

import RealNavbar from '../partials/RealNavbar';
import RealFooter from '../partials/RealFooter';

import { Spinner } from 'react-bootstrap';

class RealRedirect extends Component {

  constructor(props) {
    super(props);
    this.redirect = this.redirect.bind(this);
  };

  redirect() {
    try {
      // https://realdatingapp.com/redirect/{os}?tjclid={ACLID}
      return new Promise((resolve, reject) => {
        let path = window.location.pathname;
        var store;

        if (path.startsWith('/redirect/ios')) {
          store = 'https://apps.apple.com/us/app/real-dating-app-video-a-i/id6504808371';
        } else if (path.startsWith('/redirect/android')) {
          store = 'https://play.google.com/store/apps/details?id=com.realdatingapp.rn';
        } else {
          window.location.replace('/');
        }

        // Get ad click id if it exists
        const search = window.location.search;
        if (search.startsWith('?tjclid')) {
          const random = Math.random().toString(36).split('.')[1];
          const aclid = search.slice(8, search.length);
          // Track conversion
          fetch(`https://ads.trafficjunky.net/ct?a=1000487091&member_id=1006389131&cb=${random}&cti=${random}&ctv=0.01&ctd=Redirect&aclid=${aclid}`).then(() => {
            resolve(store);
          }).catch(err => {
            reject(err);
          });
        } else {
          resolve(store);
        }
      }).then((store) => {
        // Redirect
        setTimeout(() => {
          window.location.assign(store);
        }, 1000);
      }).catch(err => {
        console.error(err);
        window.location.replace('/');
      });
    } catch (err) {
      console.error(err);
      window.location.replace('/');
    }
  }

  render() {
    this.redirect();
    return (
      <>
        <div className="redirect">
          <RealNavbar />
          <div className='d-flex justify-content-center'>
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
              variant='danger'
            />
          </div>
          <div className='d-flex justify-content-center p-4'>
            <p className="fs-lg text-muted mb-6">
              Redirecting to the app...
            </p>
          </div>
          <RealFooter />
        </div>
      </>
    )
  }
}

export default RealRedirect;
